@font-face {
  font-family: "Candal";
  src: url("../public/Candal-Regular.ttf") format("truetype"),
    url("../public/OpenSans-ExtraBold.ttf") format("truetype");
}


/* Background container */
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(8px);
  animation: slideBackground 10s infinite;
}

/* Background animation keyframes */
@keyframes slideBackground {
  0% {
    background: url("bg.jpg") no-repeat center center fixed;
    background-size: cover;
  }
  50% {
    background: url("bg-1.jpg") no-repeat center center fixed;
    background-size: cover;
  }
  100% {
    background: url("bg.jpg") no-repeat center center fixed;
    background-size: cover;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  /* background:url(bg-1.jpg) no-repeat 0px 0px;
	background-size:cover;
	-webkit-background-size:cover;
	-moz-background-size:cover;
	-o-background-size:cover;
	-ms-background-size:cover; */
  overflow: hidden;
}
/*-- banner --*/
.banner-info-w3l img {
  margin-top: 1em;
}
.banner-info-w3l {
  background: url(dott.png) repeat 0px 0px;
  text-align: center;
  padding-top: 2em;
  padding-bottom: 16em;
  animation: fadeIn 2s ease-in-out;
  opacity: 0; /* Start hidden */
  animation-fill-mode: forwards; /* Retain final animation state */
  overflow: hidden;
}

/* Define the keyframes for the fade-in effect */
@keyframes fadeIn {
  0% {
    opacity: 0; /* Fully hidden */
  }
  100% {
    opacity: 1; /* Fully visible */
  }
}
/*-- banner-info --*/
.banner-info-w3l h1 {
  font-size: 4em;
  color: #fff;
  font-family: "Candal", sans-serif;
  margin-top: 14px;
}
.banner-info-w3l h1 span {
    display: block;
    font-size: 0.25em;
    letter-spacing: 10px;
    line-height: 1.5;
    padding-left: 19px;
    
    /* Initial position off to the right */
    position: relative;
    animation: slideRightToLeft 3s ease-in-out forwards;
  }
  
  @keyframes slideRightToLeft {
    0% {
      right: -100%; /* Start off the screen from the right */
    }
    100% {
      right: 0; /* End in the correct position */
    }
  }
  


  .banner-info-w3l h2 {
    font-size: 1em;
    color: #fff;
    line-height: 1.8em;
    margin: 3em auto 5em;
    width: 50%;
    opacity: 0; /* Initially hidden */
    transform: translateY(-20px); /* Start slightly above the current position */
    animation: slideIn 1s ease-out 4s forwards; /* Animation starts after 4 seconds */
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(-20px); /* Start slightly above */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End in the correct position */
    }
  }
  
/*--responsiv--*/
@media (max-width: 1440px) {
}
@media (max-width: 1366px) {
}
@media (max-width: 1280px) {
}
@media (max-width: 1080px) {
  .banner-info-w3l h1 {
    font-size: 3.5em;
  }
}
@media (max-width: 991px) {
  .banner-info-w3l {
    padding: 20em 0;
  }
  .banner-info h2 {
    width: 55%;
  }
}
@media (max-width: 800px) {
  .banner-info-w3l h2 {
    width: 68%;
    margin: 3em auto 4em;
  }
  .banner-info-w3l h1 span {
    letter-spacing: 5px;
  }
}
@media (max-width: 768px) {
  .banner-info-w3l h1 span {
    letter-spacing: 5px;
  }
}
@media (max-width: 736px) {
  .banner-info-w3l h1 span {
    letter-spacing: 5px;
  }

  .banner-info-w3l h2 {
    width: 80%;
    margin: 3em auto 4em;
    font-size: 0.95em;
  }
  .banner-info-w3l {
    padding: 20em 0;
  }
}
@media (max-width: 667px) {
  .banner-info-w3l h2 {
    width: 90%;
  }
  .banner-info-w3l h1 span {
    letter-spacing: 5px;
  }
}
@media (max-width: 640px) {
  .banner-info-w3l h1 span {
    letter-spacing: 5px;
  }
}
@media (max-width: 600px) {
  .copyright p {
    font-size: 0.92em;
  }
  .banner-info-w3l h2 {
    margin: 3em auto 3em;
  }
  .banner-info-w3l h1 span {
    letter-spacing: 5px;
  }
}
@media (max-width: 538px) {
  .banner-info-w3l h1 span {
    letter-spacing: 5px;
  }

  .banner-info-w3l h1 {
    font-size: 3.2em;
  }
}
@media (max-width: 480px) {
  .banner-info-w3l h1 span {
    letter-spacing: 5px;
  }

  .banner-info-w3l h1 {
    font-size: 3em;
  }
}
@media (max-width: 384px) {
  .banner-info-w3l h1 span {
    letter-spacing: 4px;
  }

  .banner-info-w3l {
    padding: 15em 0;
  }
  .banner-info-w3l h2 {
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .banner-info-w3l h1 span {
    display: block;
    font-size: 0.25em;
    letter-spacing: 3px;
    line-height: 1.9;
  }
  .banner-info-w3l h1 {
    font-size: 2.2em;
  }
}
@media (max-width: 320px) {
  .banner-info-w3l h2 {
    width: 99%;
    font-size: 0.8em;
  }
}
